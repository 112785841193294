import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { FullComponent  } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { RoleGuard      } from './guards';
import { ensureSignedIn, isNotSignedIn } from 'app/guards/auth.guard';

export const routes: Routes = [
  {
    path: '', component: FullComponent,
    canActivate: [ensureSignedIn({})],
    children: [{ path: '', redirectTo: 'init/time-tracking/list', pathMatch: 'full' },
               { path: 'init',          component: FullComponent, children: [{ path: '**', component: FullComponent }]},
               { path: 'time-tracking', canActivate: [RoleGuard], loadChildren: () => import('./components/time-tracking/time-tracking.module').then(m => m.TimeTrackingModule)                         },
               { path: 'invoices',      canActivate: [RoleGuard], loadChildren: () => import('./components/invoices/invoices.module'          ).then(m => m.InvoicesModule), data: {customerOnly: true} }
  // ]}, {
  //   path: '', component: BlankComponent,
  //   canActivate: [isNotSignedIn({})],
  //   children: [{ path: 'auth', loadChildren: () => import('./components/authentication/authentication.module').then(m => m.AuthenticationModule) }
  ]}, {
    path: '**',
    redirectTo: '/init/time-tracking/list',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
