import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subject, interval, of, timer, throwError, Subscription } from 'rxjs';
import { takeWhile, tap, switchMap, map, takeUntil, catchError } from 'rxjs/operators';
import { WebSocketSubject, WebSocketSubjectConfig } from 'rxjs/webSocket';

import { AutoReloadService, CountersService, SessionStorageService, UserService } from './index.s';
import { WsAuthResponse, WsMessageModel, WsResponse } from '@shared/models';
import { environment } from 'environments/environment';

const RECONNECT_INTERVAL = 5000;
const RECONNECT_ATTEMPTS = 3;

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  private readonly WS_TOKEN_API = `${environment.apiUrl}api/portal/v3/web_socket_authentications`;
  private readonly WS_API       = `${environment.apiUrl.replace('https://', 'wss://')}cable`;

  private webSocket:         WebSocketSubject<WsResponse>;
  private wsPing:            Date;
  private reconnectLoop:     Observable<any>;

  private pingSub:           Subscription;
  private stopPing:          Subject<void> = new Subject<void>();
  private pingHandler:       NodeJS.Timeout;

  private pills:             number[] | string;

  private latestMessageUUID: string;
  private goBackgroundAt:    Date;
  private backgroundHandler: boolean;
  constructor(
    private http:                  HttpClient,
    private userService:           UserService,
    private countersService:       CountersService,
    private autoReloadService:     AutoReloadService,
    private sessionStorageService: SessionStorageService
  ) { }

  private requestWebSocketToken(): Observable<string> {
    return this.http.post<WsAuthResponse>(this.WS_TOKEN_API, {}).pipe(map(data => data.websocket_jwt));
  }

  connect(): void {
    this.requestWebSocketToken().pipe(
      switchMap(token => {
        let url           = `${this.WS_API}?jid=${token}`;
        let closeObserver = { next: (event: CloseEvent) => this.webSocket = null };
        let config: WebSocketSubjectConfig<WsResponse> = Object.assign({ url, closeObserver })

        this.webSocket = new WebSocketSubject(config);
        return this.webSocket;
      }),
      tap(message => {
        if (message.type === 'welcome') this.reloadWebSockets();
        if (message.type === 'ping') {
          this.wsPing = new Date(+message.message * 1000);
          if (!this.pingHandler) this.startOnlineCheck();
        }
        if ((message.message as WsMessageModel)?.id && (!this.latestMessageUUID || this.latestMessageUUID !== (message.message as WsMessageModel).event_uuid)) {
          this.latestMessageUUID = (message.message as WsMessageModel).event_uuid;
          this.countersService.resourceStateTransitionHandler(message.message as WsMessageModel)
        }
      })
    ).subscribe(
      message => {},
      err     => {
        if (this.reconnectLoop) return err;
        return this.reconnect();
      }
    );
  }

  private reconnect(): void {
    if (!this.reconnectLoop) {
      this.reconnectLoop = interval(RECONNECT_INTERVAL).pipe(
        takeWhile((v, index) => {
          if (this.webSocket) return false;
          return index < RECONNECT_ATTEMPTS;
        }),
        switchMap(() => this.healthCheck()),
        switchMap(res => {
          if (res === 'OK') {
            this.connect();
            return of(null);
          } else {
            let error = 'No connection.';
            return throwError(error);
          }
        })
      );

      this.reconnectLoop.subscribe(
        () => { },
        () => { },
        () => { if (!this.webSocket) this.closeWebSocket(true); }
      );
    }
  }

  closeWebSocket(fallback: boolean = false): void {
    if (this.webSocket) this.webSocket.complete();
    if (fallback) this.autoReloadService.fallbackToTimerReload().subscribe();
  }

  reloadWebSockets(pills: number[] = null): void {
    this.unsubscribe();
    if (pills?.length) this.pills = pills;
    else {
      let activePills = this.sessionStorageService.activePills;
      if (this.userService.isInternal) this.pills = activePills.length ? activePills.map(p => p.location)       : 'all';
      if (this.userService.isCustomer) this.pills = activePills.length ? activePills.map(p => p.company_number) : null || [+this.userService.currentUserValue?.customer_companies[0]?.company_number];
    }
    this.subscribe();
    if (!this.backgroundHandler) this.startBackgroundHandler();
  }

  private subscribe(): void {
    this.sendCommand('subscribe', this.getChannelName(), this.getFilterlName(), this.pills);
  }

  private unsubscribe(): void {
    if (this.pills) this.sendCommand('unsubscribe', this.getChannelName(), this.getFilterlName(), this.pills);
    this.pills = null;
  }

  private getChannelName(): string {
    if (this.userService.isInternal) return 'InternalLocationsChannel';
    if (this.userService.isCustomer) return 'CustomerCompaniesChannel';
  }

  private getFilterlName(): string {
    if (this.userService.isInternal) return 'identifiers';
    if (this.userService.isCustomer) return 'company_numbers';
  }

  private sendCommand(command: string, channel: string, filter: string, identifiers: any): void {
    if (this.webSocket) this.webSocket.next(<any>{ command, identifier: JSON.stringify({ channel, [filter]: identifiers }) });
    else console.error('Send error!');
  }

  private startOnlineCheck(): void {
    clearInterval(this.pingHandler);
    if (this.webSocket) this.pingHandler = setInterval(() => this.onlineCheck(), 1000);
  }

  private onlineCheck(): void {
    if      (!this.webSocket) clearInterval(this.pingHandler);
    else if (this.wsPing && (this.wsPing.getTime() + 10*1000) < new Date().getTime()) {
      clearInterval(this.pingHandler);
      this.pingHandler = null

      this.closeWebSocket();
      this.pingSub = this.startPing().subscribe();
    }
  }

  private startPing(): Observable<any> {
    return timer(0, 5000).pipe(
      switchMap(() => this.reloadWebsocketsWhenOnline()),
      takeUntil(this.stopPing)
    );
  }

  private reloadWebsocketsWhenOnline() {
    return this.healthCheck().pipe(
      tap(res => {
        if (!res || res === 'OK') {
          this.pingSub.unsubscribe();
          this.stopPing.next();
          this.reconnect();
        }
      })
    );
  }

  private healthCheck(): Observable<any> {
    return this.http.get(`${environment.apiUrl}healthz`).pipe(
      map((res: Response) => res.json()),
      catchError(error => of(error))
    );
  }

  wsOnline() {
    return this.webSocket;
  }

  private startBackgroundHandler(): void {
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === 'hidden') this.goBackgroundAt = new Date();
      if (document.visibilityState === 'visible') {
        if (!this.webSocket || (this.wsPing         && new Date().getTime() - this.wsPing.getTime()         > 15*1000) ||
                               (this.goBackgroundAt && new Date().getTime() - this.goBackgroundAt.getTime() > 5*60*1000)) {
          this.fullForceReload();
        } this.goBackgroundAt = null;
      }
    });
  }

  private fullForceReload(): void {
    this.unsubscribe();
    this.closeWebSocket();
    this.wsPing        = null;
    this.reconnectLoop = null;
    this.pingSub       = null;
    this.stopPing      = null;
    this.pingHandler   = null;

    setTimeout(() => this.connect());
  }

}
